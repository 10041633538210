<template>
    <div id="box">
        <Header :headerIndex="4" :nextLevel="5"></Header>
        <div class="container">
            <div class="flex-box flex-col-start ptb30 m1340 flex-col-stretch">
                <LeftNav :leftIndex="5"></LeftNav>
                <div class="flex-grow-1 ml20 pt30 plr30 personal coins_account">
                    <div class="flex-box pb20">
                        <div class="fs20 fwb pr20">{{$t('ep')}}</div>
                    </div>
                    <div class="mb40 flex-box flex-between">
                        <div class="coin_box ">
                            <div class="fs14 col5 pb5">EP</div>
                            <div class="fs24 colb fwb">{{ user.ep | numberToCurrency }}</div>
                        </div>
                    </div>
                    <template>
                        <div class="fs20 fwb pr20 pb30" >TRANSFER</div>
                        <div class="fs16 col5 pb10">EP</div>
                        <el-input class="input mb30" placeholder="Number" v-model="num"></el-input>
                        <div class="fs16 col5 pb10">Account Number Of The Recipient</div>
                        <el-input class="input mb30" placeholder="Enter" v-model="idnum"></el-input>
                        <div class="fs16 col5 pb10">Password</div>
                        <el-input class="input mb30" placeholder="Password" v-model="password" type="password"></el-input>
                     
                        <a href="javascript:;" class="convert mb40" @click="submitexchange">TRANSFER</a>

                        <div class="liiustrate p40 mb40" style="background:rgb(7 202 233 / 10%)">
                          <div class="fs20 fwb col10 pb5">EP Service Email</div>
                          <div class="fs16 col3">
                            smamodle@gmail.com
                          </div>
                          <!--<div class="fs20 fwb col10 pb5 pt20">Apply for Template</div>
                          <div class="fs16 col3 ep_mail">
                            <span>账户ID：</span>xxx<br/>
                            <span>EP数量：</span>1xxx<br/>
                            <span>账户持有人：</span>xxx<br/>
                            <span>推荐人ID：</span>xxxx<br/>
                            <span>收款账户：</span>8888888888<br/>
                            <span>开户行名称：</span><br/>
                            <span>开户行地址：</span><br/>
                          </div>-->
                        </div>

                        <div class="liiustrate p40 mb40">
                            <div class="fs20 fwb col10 pb20">LIIUSTRATE</div>
                            <div class="fs16 col3">
                                <div class="">

                                    <div class="" v-html="articledetail"></div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="fs20 fwb pb20">{{$t('records')}}</div>
                    <div class="title_box flex-box fs16 col3">
                        <div class="title_item">{{$t('date')}}</div>
                        <div class="title_item">{{$t('types')}}</div>
                        <div class="title_item">{{$t('number')}}</div>
                    </div>
                    <div class="list_box flex-box fs16 col3" v-for="item in list">
                        <div class="list_item">{{ item.createtime_text }} </div>
                        <div class="list_item " v-if="item.type==5">ID:{{item.ortheruser.idnum}} {{item.ortheruser.nickname}}</div>
                        <div class="list_item " v-else>{{item.type_text}}{{ (item.type==4||item.type==2||item.type==7||item.type==8||item.type==9)&&item.ortheruser?'【'+item.ortheruser.id+'】':'' }}</div>
                        <div class="list_item">{{item.num}}</div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import { numberToCurrency } from "../assets/filters";
import cookie from 'vue-cookies'
export default {
    filters: {
        numberToCurrency
    },
    data() {
        return {
            num:'',
            idnum:'',
            password:'',
            user:'',
            articledetail:'',
            //人机验证 详情看网址 https://segmentfault.com/a/1190000023733698?sort=newest
            sitekey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
            list:{}
        };
    },
    created(){
        this.initdata()
    },
    mounted() {
        this.loaded();
    },
    methods: {
        initdata(){
            this.loglist()
            this.getuser()
            this.config()
        }, 
        config(){
            this.$api.config({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.articledetail=res.data.ep
                } else {
                    // that.$message.error(res.msg);
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
            })
        },
        getuser(){
            this.$api.apiUserInfo({}).then(ret=>{
				if(ret.code==1){

					this.user = ret.data.userinfo;
                   
				}
			})
        },
        loglist(){
            this.$api.apiEploglist({}).then(ret=>{
                if(ret.code==1){
                    this.list=ret.data
                }
            })
        },
        submit: function (token) {
            console.log(token);
        },
        loaded() {
            setTimeout(() => {
                window.grecaptcha.render("grecaptcha", {
                    sitekey: this.sitekey,
                    callback: this.submit
                });
            }, 200);
        },
        submitexchange(){
          console.log(88)  
          if(!this.num||this.num<=0){
            // return this.$message.error('请填写转账数量')
            return this.$message({
                message: '请填写转账数量',
                type: 'error',
                offset: window.screen.height / 2
            })
          }
          if(!this.idnum){
            // return this.$message.error('请填写转账账号')
            return this.$message({
                message: '请填写转账账号',
                type: 'error',
                offset: window.screen.height / 2
            })

          }
          if(!this.password){
            // return this.$message.error('请填写交易密码')
            return this.$message({
                message: '请填写交易密码',
                type: 'error',
                offset: window.screen.height / 2
            })

          }
          var data={num:this.num,idnum:this.idnum,password:this.password}
          this.$api.apiEpexchange(data).then(ret=>{
            if(ret.code==1){
                // this.$message.success(ret.msg)
                this.$message({
						message: ret.msg,
						type: 'success',
						offset: window.screen.height / 2
					})
                window.location.reload(true)                 
            }else{
                // this.$message.error(ret.msg)
                this.$message({
                    message: ret.msg,
                    type: 'error',
                    offset: window.screen.height / 2
                })

            }
          })

        },
    }
};
</script>
